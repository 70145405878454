import React from 'react'
import PageLayout from '../components/shared/PageLayout'
import Loader from '../components/shared/Loader'
import MspTable from '../components/msps/MspTable'
import { useMspApi } from '../lib/api'

const MspPage = ({ loginData }) => {
  const [{ state }, updateMsp] = useMspApi({ token: loginData.token })

  if (!state.isFetching) {
    return (
      <PageLayout>
        <h1>MSPs</h1>
        <article>
          <MspTable
            msps={state.msps}
            updateMsp={updateMsp}
            isInteractable={(loginData.user.role !== 'reporter')}
          />
        </article>
      </PageLayout>
    )
  } else {
    return (
      <PageLayout>
        <Loader />
      </PageLayout>
    )
  }
}

export default MspPage
