import React, { useMemo } from 'react'

const SgCountsInfo = ({ newsItems, publicationItems }) => (
  <p>
    <em>
      {useMemo(() => {
        return `There are ${newsItems.length} news items and ${publicationItems.length} publication items`
      }, [newsItems, publicationItems])}
    </em>
  </p>
)

const SgEntry = (entry) => (
  <div className="sg-entry">
    <p className="sg-entry__title">
      <a href={entry.entry.url}>{entry.entry.title}</a>
    </p>
  </div>
)

const SgEntryList = ({ entries }) => {
  const { newsItems, publicationItems } = useMemo(
    () =>
      entries.reduce(
        (acc, entry) => {
          if (entry.type === 'News') {
            acc.newsItems.push(entry)
          } else {
            acc.publicationItems.push(entry)
          }
          return acc
        },
        { newsItems: [], publicationItems: [] }
      ),
    [entries]
  )

  return (
    <article className="sg-entries__container">
      <SgCountsInfo newsItems={newsItems} publicationItems={publicationItems} />
      <div className="sg-entries__column">
        <h2 className="sg-entries__header">News</h2>
        {newsItems.map((entry, index) => (
          <SgEntry key={index} entry={entry} />
        ))}
      </div>

      <div className="sg-entries__column">
        <h2 className="sg-entries__header">Publications</h2>
        {publicationItems.map((entry, index) => (
          <SgEntry key={index} entry={entry} />
        ))}
      </div>
    </article>
  )
}

export default SgEntryList
