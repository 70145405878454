import React from 'react'
import { getBBDateEstimate } from '../../lib/utils'

// Generates a header with a link to the BB
// We need to use startDate + 1 day for this
const ShortHeader = ({ text, date }) => {
  const rootUrl = 'https://bb.parliament.scot/'
  const typeStr = (text === 'Motions' ? 'Motions' : 'Questions')
  const dateStr = getBBDateEstimate(date).format('YYYYMMDD')
  const url = `${rootUrl}${typeStr}#${dateStr}`
  return (
    <>
      <strong> {text} </strong> &ndash; <a href={url}>click for full text</a>
    </>
  )
}

export default ShortHeader
