import React from 'react'

const Downloads = () => (
  <>
    <h2>Downloads</h2>
    <article className="downloads">
      <a className="download" href={`${process.env.REACT_APP_CONSULTATIONS_CUT_SPREADSHEET_URL}`}>
          Cut Down Spreadsheet
      </a>
      <a className="download" href={`${process.env.REACT_APP_TRACKER_SPREADSHEET_URL}`}>
          Full Tracker Spreadsheet
      </a>
      <a className="download" href={`${process.env.REACT_APP_NFUS_TRACKER_SPREADSHEET_URL}`}>
          NFUS Tracker Spreadsheet
      </a>
    </article>
  </>
)

export default Downloads
