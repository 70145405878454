import React from 'react'
import MentionsLine from '../shared/MentionsLine'

const WmWrittenAnswer = ({ answer, showScot }) => {
  const hasMentions = answer.mentions && answer.mentions.length
  return (
    <p>
      <a href={answer.url}>{answer.title}</a>
      {(showScot && answer.isScot ? <> &ndash; {answer.mp}</> : '')}
      {(showScot && hasMentions ? <> &ndash; <MentionsLine mentions={answer.mentions} /></> : '')}
    </p>
  )
}

export default WmWrittenAnswer
