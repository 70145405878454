import React from 'react'
import LinkedText from '../shared/LinkedText'
import MentionsLine from '../shared/MentionsLine'

const OralQuestion = ({ question, isShort }) => {
  const hasMentions = question.mentions && question.mentions.length
  const mentionsBullet = (
    <ul>
      <li>
        <MentionsLine mentions={question.mentions} />
      </li>
    </ul>
  )

  if (!isShort) {
    return (
      <>
        <p className='oral-question'>
          <strong>{question.oralOrder}. {question.msp.name} ({question.msp.location}) ({question.msp.party}): </strong>
          <LinkedText text={question.body} /> {' '}
          <LinkedText text={question.ref} />
        </p>
        {(hasMentions ? mentionsBullet : '')}
      </>
    )
  } else {
    return (
      <p className='oral-question'>
        <strong>{question.oralOrder}. {question.msp.name} </strong> &ndash;&nbsp;
      </p>
    )
  }
}

export default OralQuestion
