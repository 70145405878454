import request from 'request-promise-native'
import { useState, useEffect } from 'react'

const apiRoot = process.env.REACT_APP_API_URL

export const useTagApi = ({ token }) => {
  const [tagState, setState] = useState({ isFetching: true })

  const getTags = async () => {
    const reqOptions = {
      method: 'GET',
      url: `${apiRoot}/tags`,
      headers: { Authorization: `Bearer ${token}` }
    }

    const result = await request(reqOptions)
    const parsed = await JSON.parse(result)
    const tags = parsed.data
    setState({ tags, isFetching: false })
  }

  const createTag = async (text) => {
    text = text.trim()
    const data = { data: { text: text } }
    const json = await JSON.stringify(data)
    const reqOptions = {
      method: 'POST',
      url: `${apiRoot}/tags`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: json
    }
    await request(reqOptions)
    await getTags()
  }

  const updateTag = async (id, text) => {
    text = text.trim()
    const data = { data: { text: text } }
    const json = await JSON.stringify(data)
    const reqOptions = {
      method: 'PUT',
      url: `${apiRoot}/tags/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: json
    }
    await request(reqOptions)
    await getTags()
  }

  const deleteTag = async (id) => {
    const reqOptions = {
      method: 'DELETE',
      url: `${apiRoot}/tags/${id}`,
      headers: { Authorization: `Bearer ${token}` }
    }
    await request(reqOptions)
    await getTags()
  }

  const addTagToInquiry = async ({ inquiryId, tagId }) => {
    const data = { data: { inquiryToAddToId: inquiryId } }
    const json = await JSON.stringify(data)

    const reqOptions = {
      method: 'PUT',
      url: `${apiRoot}/tags/${tagId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: json
    }
    await request(reqOptions)
  }

  const deleteTagFromInquiry = async ({ inquiryId, tagId }) => {
    const data = { data: { inquiryToDeleteFromId: inquiryId } }
    const json = await JSON.stringify(data)

    const reqOptions = {
      method: 'PUT',
      url: `${apiRoot}/tags/${tagId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: json
    }
    await request(reqOptions)
  }

  return [
    { tagState },
    getTags,
    createTag,
    updateTag,
    deleteTag,
    addTagToInquiry,
    deleteTagFromInquiry
  ]
}

export const useInquiryApi = ({ token }) => {
  const [inquiryState, setState] = useState({ isFetching: true })
  const [orgs, setOrgs] = useState([])

  const getInquiries = async (query) => {
    if (!inquiryState.isFetching) setState({ isFetching: true })

    const reqOptions = {
      method: 'GET',
      qs: query,
      url: `${apiRoot}/inquiries`,
      headers: { Authorization: `Bearer ${token}` }
    }

    const result = await request(reqOptions)
    const parsed = await JSON.parse(result)
    const inquiries = parsed.data

    if (inquiries) {
      // Clean up data on our end
      for (const inq of inquiries) {
        if (!inq.url) inq.url = ''
        if (!inq.added) inq.added = ''
        if (!inq.openedDate) inq.openedDate = ''
        if (!inq.deadlineDate) inq.deadlineDate = ''
      }

      const sortedInquiries = inquiries.sort((a, b) => {
        return new Date(b.added) - new Date(a.added)
      })
      setState({ inquiries: sortedInquiries, isFetching: false })
    }
  }

  const createInquiry = async (inquiry) => {
    inquiry.name = inquiry.name.trim()
    inquiry.url = inquiry.url.trim()
    inquiry.orgs = inquiry.orgs.map((o) => {
      o.name = o.name.trim()
      o.url = o.name.trim()
      return o
    })

    if (inquiry.added === '') inquiry.added = null
    if (inquiry.openedDate === '') inquiry.openedDate = null
    if (inquiry.deadlineDate === '') inquiry.deadlineDate = null

    const data = { data: inquiry }
    const json = await JSON.stringify(data)
    const reqOptions = {
      method: 'POST',
      url: `${apiRoot}/inquiries`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: json
    }
    try {
      await request(reqOptions)
      return 'Created Inquiry'
    } catch (err) {
      return err
    }
  }

  const updateInquiry = async (inquiry) => {
    if (!inquiry.id) return
    if (inquiry.added === '') inquiry.added = null
    if (inquiry.openedDate === '') inquiry.openedDate = null
    if (inquiry.deadlineDate === '') inquiry.deadlineDate = null
    delete inquiry.tags // Don't use inquiry endpoint to update tags

    const updatedInquiries = inquiryState.inquiries.map((inq) =>
      inq.id === inquiry.id ? inquiry : inq
    )
    setState({ ...inquiryState, inquiries: updatedInquiries })

    const data = { data: inquiry }
    const json = await JSON.stringify(data)
    const reqOptions = {
      method: 'PUT',
      url: `${apiRoot}/inquiries/${inquiry.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: json
    }
    await request(reqOptions)
  }

  const deleteInquiry = async (id) => {
    const updatedInquiries = inquiryState.inquiries.filter(
      (inq) => inq.id !== id
    )
    setState({ ...inquiryState, inquiries: updatedInquiries })

    const reqOptions = {
      method: 'DELETE',
      url: `${apiRoot}/inquiries/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
    await request(reqOptions)
  }

  const getOrganisations = async () => {
    const reqOptions = {
      method: 'GET',
      url: `${apiRoot}/organisations`,
      headers: { Authorization: `Bearer ${token}` }
    }
    const result = await request(reqOptions)
    const parsed = await JSON.parse(result)
    setOrgs(parsed.data)
  }

  return [
    { inquiryState },
    getInquiries,
    createInquiry,
    updateInquiry,
    deleteInquiry,
    getOrganisations,
    orgs
  ]
}

export const useMspApi = ({ token }) => {
  const [state, setState] = useState({ isFetching: true })

  const getMsps = async () => {
    if (!state.isFetching) setState({ isFetching: true })
    const reqOptions = {
      method: 'GET',
      url: `${apiRoot}/msps`,
      headers: { Authorization: `Bearer ${token}` }
    }
    const result = await request(reqOptions)
    const parsed = await JSON.parse(result)
    const msps = parsed.data
    if (msps) {
      setState({ msps, isFetching: false })
    }
  }

  const createMsp = async (msp) => {
    const data = { data: msp }
    const json = await JSON.stringify(data)
    const reqOptions = {
      method: 'POST',
      url: `${apiRoot}/msps`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: json
    }
    await request(reqOptions)
    await getMsps()
  }

  const updateMsp = async (msp) => {
    const data = { data: msp }
    const json = await JSON.stringify(data)
    const reqOptions = {
      method: 'PUT',
      url: `${apiRoot}/msps/${msp.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: json
    }
    await request(reqOptions)
    await getMsps()
  }

  const deleteMsp = async (id) => {
    const reqOptions = {
      method: 'DELETE',
      url: `${apiRoot}/msps/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
    await request(reqOptions)
    await getMsps()
  }

  // eslint-disable-next-line
  useEffect(() => {
    getMsps()
  }, [])

  return [{ state }, createMsp, updateMsp, deleteMsp]
}

export const useEntryApi = ({ token, endpoint }) => {
  const [entryState, setState] = useState({ isFetching: true })

  const getEntries = async ({ startDate, endDate }) => {
    if (!entryState.isFetching) setState({ isFetching: true })
    const reqOptions = {
      method: 'GET',
      url: `${apiRoot}/${endpoint}`,
      qs: {
        'start-date': startDate.format('YYYY-MM-DD'),
        'end-date': endDate.format('YYYY-MM-DD')
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
    const result = await request(reqOptions)
    const parsed = await JSON.parse(result)
    const newData = parsed.data
    if (newData) {
      setState({
        isFetching: false,
        startDate,
        endDate,
        ...newData
      })
    }
  }

  return [{ entryState }, getEntries]
}

export const useClientApi = ({ token }) => {
  const [clientState, setState] = useState({ isFetching: true })

  const getClients = async () => {
    if (!clientState.isFetching) setState({ isFetching: true })
    const reqOptions = {
      method: 'GET',
      url: `${apiRoot}/clients`,
      headers: { Authorization: `Bearer ${token}` }
    }
    const result = await request(reqOptions)
    const parsed = await JSON.parse(result)
    const newData = parsed.data
    if (newData) {
      setState({
        isFetching: false,
        clients: [...newData]
      })
    }
  }

  const createClient = async (client) => {
    client.name = client.name.trim()
    client.notes = client.notes.trim()
    client.keywords = client.keywords.map((kw) => {
      kw.keyword = kw.keyword.trim()
      kw.notes = kw.notes.trim()
      return kw
    })

    const data = { data: client }
    const json = await JSON.stringify(data)
    const reqOptions = {
      method: 'POST',
      url: `${apiRoot}/clients`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: json
    }
    await request(reqOptions)
    await getClients()
  }

  const updateClient = async (client) => {
    if (!client.id) return

    // Set new state locally
    const cleanedClient = { ...client }
    cleanedClient.keywords = client.keywords.filter(
      (kw) => kw && kw.keyword && /\S/.test(kw.keyword)
    )
    const updatedClients = clientState.clients.map((c) =>
      c.id === cleanedClient.id ? cleanedClient : c
    )
    setState({ ...clientState, clients: updatedClients })

    // Send update - send empty/deleted keywords
    const data = { data: client }
    const json = await JSON.stringify(data)
    const reqOptions = {
      method: 'PUT',
      url: `${apiRoot}/clients/${client.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: json
    }
    await request(reqOptions)
  }

  const deleteClient = async (id) => {
    const updatedClients = clientState.clients.filter((c) => c.id !== id)
    setState({ ...clientState, clients: updatedClients })

    const reqOptions = {
      method: 'DELETE',
      url: `${apiRoot}/clients/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
    await request(reqOptions)
  }

  return [{ clientState }, getClients, updateClient, createClient, deleteClient]
}
