import React, { useState, useEffect } from 'react'
import PageLayout from '../components/shared/PageLayout'
import Loader from '../components/shared/Loader'
import NdConfirmAlert from '../components/shared/NdConfirmAlert'
import { useClientApi } from '../lib/api'
import PearlsField from '../components/keywords/PearlsField'
import KeywordForm from '../components/keywords/KeywordForm'
import KeywordEntry from '../components/keywords/KeywordEntry'

const KeywordPage = ({ loginData }) => {
  const [
    { clientState },
    getClients,
    updateClient,
    createClient,
    deleteClient
  ] = useClientApi({ token: loginData.token })
  const [filterString, setFilterString] = useState('')
  const [selectedIndex, select] = useState(-1)

  useEffect(() => {
    getClients()
    // eslint-disable-next-line
  }, [])

  const create = (client) => {
    createClient(client)
    select(-1)
  }

  const update = (client) => {
    updateClient(client)
    select(-1)
  }

  const del = (id) => {
    NdConfirmAlert({
      title: 'Confirm',
      message: 'Delete this client?',
      confirmFunc: () => {
        deleteClient(id)
        select(-1)
      }
    })
  }

  let rows = (
    <div className='empty-container note'>
      None
    </div>
  )

  if (!clientState.isFetching) {
    const clients = [...clientState.clients].sort((a, b) => {
      a.keywords.sort((kA, kB) => kA.keyword.localeCompare(kB.keyword))
      return a.name.localeCompare(b.name)
    })

    let filteredClients = [...clients]
    if (filterString !== '') {
      filteredClients = clients.filter(c => {
        const kws = c.keywords.map(kw => kw.keyword).join(' ')
        if (c.name.toLowerCase().includes(filterString.toLowerCase()) ||
          kws.toLowerCase().includes(filterString.toLowerCase())) {
          return true
        } else return false
      })
    }
    if (filteredClients.length) {
      rows = filteredClients.map((c, i) => {
        if (i === selectedIndex) {
          return (
            <KeywordForm
              key={c.id}
              isCreateForm={false}
              initialData={c}
              clients={clients}
              createClient={create}
              updateClient={update}
              deleteClient={del}
            />
          )
        } else {
          return (
            <KeywordEntry
              key={c.id}
              client={c}
              select={() => select(i)}
              isInteractable={(loginData.user.role !== 'reporter')}
            />
          )
        }
      })
    }

    return (
      <PageLayout>
        <h1>Client Keywords</h1>

        <article>
          <PearlsField clients={clients} />
        </article>

        <article>
          <div className={(loginData.user.role === 'reporter' ? 'disable' : '')}>
            <h2>Add New Client</h2>
            <KeywordForm
              isCreateForm
              clients={clients}
              createClient={create}
              updateClient={update}
              deleteClient={del}
            />
            <hr />
          </div>

          <h2>Client List</h2>
          <div className='grid alt-grid'>
            <div className='grid-1-4'>
              <label htmlFor='client-filter'>
                Filter by Name or Keyword
              </label>
              <input
                id='client-filter'
                type='text'
                placeholder='Filter..'
                value={filterString}
                onChange={(e) => { setFilterString(e.target.value) }}
              />
            </div>
          </div>
          <div className='min-height'>
            {rows}
          </div>
        </article>
      </PageLayout>
    )
  } else {
    return (
      <PageLayout>
        <Loader />
      </PageLayout>
    )
  }
}

export default KeywordPage
