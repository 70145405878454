import React, { Fragment, useState, useEffect } from 'react'
import InquiryTagger from './InquiryTagger'
import SourceOrgSelect from '../shared/SourceOrgSelect'
import moment from 'moment'

const InquiryForm = ({
  isCreateForm,
  initialData,
  inquiries,
  create,
  update,
  del,
  allOrgs,
  tags,
  addTagToInquiry,
  deleteTagFromInquiry,
}) => {
  const [nameIsValid, setNameValidity] = useState(true)
  if (isCreateForm || !initialData) {
    initialData = {
      id: null,
      name: '',
      url: '',
      added: moment().format('YYYY-MM-DD'),
      openedDate: '',
      deadlineDate: '',
      sourceOrgName: 'Scottish Parliament',
      orgs: [{ name: '', url: '' }],
      tags: [],
    }
  }
  const [inquiry, setInquiry] = useState(initialData)
  const [isCreatingSource, switchCreatingSource] = useState(false)

  const validateAndClean = (stripId) => {
    const data = { ...inquiry }
    if (!data.name || data.name === '' || !data.added) return false
    if (stripId) {
      delete data.id
    }
    if (inquiry.tags) {
      delete inquiry.tags // Don't use inquiry endpoint to set tags
    }
    return data
  }

  const handleCreate = () => {
    const data = validateAndClean(true)
    if (data) create(data)
  }

  const handleUpdate = () => {
    const data = validateAndClean(false)
    if (data) update(data)
  }

  const handleDelete = () => {
    del(inquiry.id)
  }

  const handleSwitchSourceInput = () => {
    switchCreatingSource(!isCreatingSource)
  }

  useEffect(() => {
    if (inquiries && inquiries.length && inquiries.find(c =>
      c.name.toLowerCase().trim() === inquiry.name.toLowerCase().trim() &&
      c.id !== inquiry.id
    )) {
      setNameValidity(false)
    } else setNameValidity(true)
  }, [inquiry, inquiries])

  const addOrg = () => {
    setInquiry({
      ...inquiry,
      orgs: [
        ...inquiry.orgs,
        {
          name: '',
          url: '',
        },
      ],
    })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
  }

  const orgInputs = inquiry.orgs.map((o, i) => {
    const changeHandler = (e, i, field) => {
      const val = e.target.value
      const newOrgs = [...inquiry.orgs]
      const orgToChange = newOrgs[i]
      if (field === 'name') orgToChange.name = val
      else if (field === 'url') orgToChange.url = val
      setInquiry({ ...inquiry, orgs: newOrgs })
    }

    return (
      <Fragment key={`org-${i}`}>
        <div className="grid-1-3">
          <input
            type="text"
            value={o.name}
            placeholder="Committee / Department Name"
            onChange={(e) => changeHandler(e, i, 'name')}
          />
        </div>
        <div className="grid-3-5">
          <input
            type="url"
            value={o.url}
            placeholder="Committee / Department URL"
            onChange={(e) => changeHandler(e, i, 'url')}
          />
        </div>
      </Fragment>
    )
  })

  let buttons = (
    <>
      <input
        className="grid-1-3 btn-primary"
        type="submit"
        disabled={!nameIsValid}
        onClick={handleUpdate}
        name="update"
        value="Update"
      />
      <input
        className="grid-3-5 btn-delete"
        type="button"
        onClick={handleDelete}
        name="delete"
        value="Delete"
      />
    </>
  )
  if (isCreateForm) {
    buttons = (
      <input
        type="submit"
        onClick={handleCreate}
        className="btn-primary grid-2-4"
        disabled={!nameIsValid}
        name="create"
        value="Create"
      />
    )
  }

  let nameError = ''
  if (!nameIsValid) {
    nameError = <p className="error">Name already exists. It must be unique.</p>
  }
  return (
    <div className="extended-form">
      <form
        className={isCreateForm ? 'grid' : 'grid grid-selected'}
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className="grid-1-5">
          <label htmlFor={`${inquiry.id}-name`}>Inquiry Name</label>
          <input
            disabled={!isCreateForm}
            className={nameIsValid ? '' : 'error'}
            id={`${inquiry.id}-name`}
            type="text"
            required
            name="name"
            placeholder="Add the inquiry name here..."
            value={inquiry.name}
            onChange={(e) => setInquiry({ ...inquiry, name: e.target.value })}
          />
          {nameError}
        </div>
        <div className="grid-1-5">
          <label htmlFor={`${inquiry.id}-source-org`}>Institution</label>
          <SourceOrgSelect
            id={`${inquiry.id}-source-org`}
            initialValue={inquiry.sourceOrgName}
            onChangeHandler={(e) =>
              setInquiry({ ...inquiry, sourceOrgName: e.target.value })
            }
            orgs={allOrgs}
            useText={isCreatingSource}
          />
        </div>

        <button
          className="grid-2-4"
          type="button"
          onClick={handleSwitchSourceInput}
        >
          {isCreatingSource
            ? 'Update Institution from List'
            : 'Add New Institution'}
        </button>
        <div className="grid-1-5">
          <label htmlFor={`${inquiry.id}-url`}>Inquiry URL</label>
          <input
            id={`${inquiry.id}-url`}
            type="text"
            name="url"
            placeholder="Add a link to the inquiry page here"
            value={inquiry.url}
            onChange={(e) => setInquiry({ ...inquiry, url: e.target.value })}
          />
        </div>

        <div className="grid-1-3">
          <label htmlFor={`${inquiry.id}-opened`}>Date Opened</label>
          <input
            id={`${inquiry.id}-opened`}
            type="date"
            name="opened"
            value={inquiry.openedDate}
            onChange={(e) =>
              setInquiry({ ...inquiry, openedDate: e.target.value })
            }
          />
        </div>
        <div className="grid-3-5">
          <label htmlFor={`${inquiry.id}-deadline`}>Date Closing</label>
          <input
            id={`${inquiry.id}-deadline`}
            type="date"
            name="deadline"
            value={inquiry.deadlineDate}
            onChange={(e) =>
              setInquiry({ ...inquiry, deadlineDate: e.target.value })
            }
          />
        </div>
        <div className="grid-1-3">
          <label htmlFor={`${inquiry.id}-added`}>Date Added</label>
          <input
            id={`${inquiry.id}-added`}
            type="date"
            name="added"
            value={inquiry.added}
            onChange={(e) => setInquiry({ ...inquiry, added: e.target.value })}
            required
          />
        </div>

        <div className="grid-1-3">
          <label> Organisations </label>
        </div>
        {orgInputs}

        <button className="grid-2-4" type="button" onClick={() => addOrg()}>
          Add Committee or Department
        </button>

        {buttons}
      </form>
      {!isCreateForm && (
        <InquiryTagger
          inquiry={inquiry}
          tags={tags}
          addTagToInquiry={addTagToInquiry}
          deleteTagFromInquiry={deleteTagFromInquiry}
        />
      )}
    </div>
  )
}

export default InquiryForm
