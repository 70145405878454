import React, { useEffect, useState } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'
import MainRouter from './views/MainRouter'
import history from './lib/history'
import config from './auth_config.json'
import Loader from './components/shared/Loader'

const AuthLayer = () => {
  const [auth0Client, setAuth0] = useState()
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [loginData, setLoginData] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({
        domain: config.domain,
        client_id: config.clientId,
        redirect_uri: window.location.origin,
        audience: config.audience
      })
      setAuth0(auth0FromHook)

      // This handles the redirect from the login page
      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        history.push(
          appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
        )
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()
      setIsAuthenticated(isAuthenticated)

      // Grab a token once when authed (lasts 24 hours by default)
      if (isAuthenticated) {
        const token = await auth0FromHook.getTokenSilently()
        const idToken = await auth0FromHook.getIdTokenClaims()
        const user = await auth0FromHook.getUser()
        user.role = idToken['http://www.demozero.net//roles'][0] || null
        setLoginData({ user, token })
      }

      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  // Trigger a redirect to the login page if not authed or loading
  useEffect(() => {
    if (loading || isAuthenticated) return
    const redir = async () => {
      await auth0Client.loginWithRedirect()
    }
    redir()
  }, [loading, isAuthenticated, auth0Client])

  if (loading || !isAuthenticated) return <Loader />
  return (
    <MainRouter
      loginData={loginData}
      logout={(args) => auth0Client.logout(args)}
    />
  )
}

export default AuthLayer
