import React, { useEffect } from 'react'
import moment from 'moment'
import { useEntryApi } from '../lib/api'
import PageLayout from '../components/shared/PageLayout'
import DateForm from '../components/shared/DateForm'
import DateInfo from '../components/shared/DateInfo'
import Loader from '../components/shared/Loader'
import SgEntryList from '../components/sg/SgEntryList'

const SgPage = ({ loginData }) => {
  const [{ entryState }, getEntries] = useEntryApi({
    token: loginData.token,
    endpoint: 'sg-entries'
  })

  useEffect(() => {
    const defaultDate = moment()
    getEntries({ startDate: defaultDate, endDate: defaultDate })
    // eslint-disable-next-line
  }, [])

  if (!entryState.isFetching) {
    return (
      <PageLayout>
        <h1>Scottish Government News & Publications</h1>

        <DateForm
          initialStartDate={entryState.startDate}
          initialEndDate={entryState.endDate}
          submitDates={getEntries}
        />
        <DateInfo
          startDate={entryState.startDate}
          endDate={entryState.endDate}
          showBBLine={false}
        />
        <SgEntryList entries={entryState.entries} />
      </PageLayout>
    )
  } else {
    return (
      <PageLayout>
        <Loader />
      </PageLayout>
    )
  }
}

export default SgPage
