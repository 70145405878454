import React, { useState } from 'react'
import NdConfirmAlert from '../../components/shared/NdConfirmAlert'

const TagItem = ({ tag, updateTag, deleteTag }) => {
  const [text, setText] = useState(tag.text)
  const [isError, setIsError] = useState(false)
  const [updateText, setUpdateText] = useState('')

  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      await updateTag(tag.id, text)
      setUpdateText('Updated!')
    } catch (error) {
      setIsError(true)
      setUpdateText('Error updating, is this tag a duplicate?')
    }
  }

  const onDelete = async (e) => {
    e.preventDefault()
    NdConfirmAlert({
      title: 'Really delete this tag?',
      message: 'This will remove it from all inquiries it is attached to as well.',
      confirmFunc: () => { deleteTag(tag.id) }
    })
  }

  return (
    <form
      className='grid grid-selected'
      onSubmit={onSubmit}
    >
      <p className='grid-1-5'>
        <strong>{tag.text}</strong> - [ID: {tag.id}]
      </p>
      <input
        className='grid-1-5'
        required
        type='text'
        id={`${tag.id}-name`}
        name='name'
        placeholder='Tag text'
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <input
        className='btn-primary'
        type='submit'
        value='Update'
      />
      <input
        className='btn-delete'
        type='button'
        value='Delete'
        onClick={onDelete}
      />
      {!!updateText.length &&
        <p className={`grid-1-5 ${isError ? 'error' : ''}`}>
          {updateText}
        </p>
      }
    </form>
  )
}

export default TagItem
