import React from 'react'

const UserInfo = ({ logout, loginData }) => {
  const nickname = loginData.user.nickname || ''
  const role = loginData.user.role || ''
  const cap = (s) => s.charAt(0).toUpperCase() + s.slice(1)
  const displayName = cap(nickname)
  const displayRole = cap(role)
  return (
    <>
      <p>{displayName}</p>
      <p>{displayRole}</p>

      <button
        onClick={() => logout({ returnTo: window.location.origin })}
      >
        Log out
      </button>
    </>
  )
}

export default UserInfo
