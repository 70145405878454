import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import QuestionsMotionsPage from './QuestionsMotionsPage'
import WrittenAnswersPage from './WrittenAnswersPage'
import WmWrittenAnswersPage from './WmWrittenAnswersPage'
import InfoPage from './InfoPage'
import MspPage from './MspPage'
import KeywordPage from './KeywordPage'
import TagsPage from './TagsPage'
import InquiryPage from './InquiryPage'
import UserInfo from '../components/shared/UserInfo'
import SgPage from './SgPage'
import '../styles/nav.css'

const environment = process.env.REACT_APP_ENVIRONMENT

const MainRouter = ({ logout, loginData }) => {
  return (
    <Router>
      {environment !== 'Production' && <p id="env-line"> {environment}</p>}
      <nav>
        <ul>
          <img id="nd-logo" src="./nd-logo.jpg" alt="Newsdirect Logo" />
          <li>
            <Link to="/">Info</Link>
          </li>
          <li>
            <Link to="/keywords">Keywords</Link>
          </li>
          <li>
            <Link to="/msps">MSPs</Link>
          </li>
          <li>
            <Link to="/tags">Tags</Link>
          </li>
          <li>
            <Link to="/inquiries">Inquiries</Link>
          </li>
          <li>
            <Link to="/questions-motions">Qs&amp;Ms</Link>
          </li>
          <li>
            <Link to="/written-answers">WAs</Link>
          </li>
          <li>
            <Link to="/wm-written-answers">WM WAs</Link>
          </li>
          <li>
            <Link to="/sg">SG</Link>
          </li>
          <li id="user-info">
            <UserInfo logout={logout} loginData={loginData} />
          </li>
        </ul>
      </nav>
      <Switch>
        <Route path="/keywords">
          <KeywordPage loginData={loginData} />
        </Route>
        <Route path="/msps">
          <MspPage loginData={loginData} />
        </Route>
        <Route path="/tags">
          <TagsPage loginData={loginData} />
        </Route>
        <Route path="/inquiries">
          <InquiryPage loginData={loginData} />
        </Route>
        <Route path="/questions-motions">
          <QuestionsMotionsPage loginData={loginData} />
        </Route>
        <Route path="/written-answers">
          <WrittenAnswersPage loginData={loginData} />
        </Route>
        <Route path="/wm-written-answers">
          <WmWrittenAnswersPage loginData={loginData} />
        </Route>
        <Route path="/sg">
          <SgPage loginData={loginData} />
        </Route>
        <Route path="/">
          <InfoPage />
        </Route>
      </Switch>
    </Router>
  )
}

export default MainRouter
