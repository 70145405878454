import React, { Fragment } from 'react'
import LastUpdated from '../shared/LastUpdated'

const KeywordEntry = ({ client, select, isInteractable }) => {
  let entryClassName = 'grid grid-hover'
  if (!isInteractable) {
    entryClassName = 'grid'
    select = null
  }
  const keywords = client.keywords.map((k, i) => {
    if (k.keyword) {
      let sources = ''
      if (k.isUniversal) sources = 'All sources'
      else if (k.isScot && !k.isUk) sources = 'Scottish sources only'
      else if (!k.isScot && k.isUk) sources = 'UK sources only'
      else if (k.isScot && k.isUk) sources = 'Scottish & UK political sources only'
      else sources = 'No coverage'

      return (
        <Fragment key={`${client.name}-${i}`}>
          <p className='grid-1-2'>{k.keyword}</p>
          <p className='grid-2-5 note'>{(k.notes ? `${k.notes}. ` : '')}{sources}</p>
        </Fragment>
      )
    } else return ''
  })

  return (
    <div
      className={entryClassName}
      onClick={select}
    >
      <h3 className='grid-1-4'>{client.name}</h3>
      <p className='grid-4-5 last-updated'>
        <LastUpdated date={client.lastUpdated} />
      </p>
      <p className='grid-1-5 note'>{client.notes}</p>
      <hr className='grid-1-5' />
      {keywords}
    </div>
  )
}

export default KeywordEntry
