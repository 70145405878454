import React from 'react'
import { getWmAnswerUrl } from '../../lib/utils'
import WmDepartmentGroup from './WmDepartmentGroup'

const WmWrittenAnswerList = ({ answers, showScot }) => {
  if (!answers || !answers.length) return <em>No answers</em>

  // Groups WM answers into an array by their department
  const groupAnswersByDepartment = () => {
    const depts = {}
    for (const answer of answers) {
      const dept = answer.department
      const answerData = {
        url: getWmAnswerUrl(answer),
        title: answer.title,
        mp: answer.mp,
        mentions: answer.mentions,
        isScot: answer.isScot
      }
      if (depts[dept]) {
        depts[dept].push(answerData)
      } else {
        depts[dept] = [answerData]
      }
    }
    const deptArr = []
    for (const k in depts) {
      deptArr.push({ department: k, answers: depts[k] })
    }
    return deptArr
  }

  const groupedData = groupAnswersByDepartment(answers)
  const departmentGroups = groupedData.map((g, i) => (
    <WmDepartmentGroup
      key={i}
      department={g.department}
      answers={g.answers}
      showScot={showScot}
    />
  ))

  return <>{departmentGroups}</>
}

export default WmWrittenAnswerList
