import React from 'react'
import MotionEntry from './MotionEntry'
import QmsSection from './QmsSection'

const MotionList = ({ motions }) => {
  return (
    <QmsSection header='Motions'>
      {motions.map((m, i) => <MotionEntry key={`${m.ref}-${i}`} motion={m} />)}
    </QmsSection>
  )
}

export default MotionList
