import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const PearlsField = ({ clients }) => {
  const [buttonText, setButtonText] = useState('Copy All Keywords')
  const [wmButtonText, setWmButtonText] = useState('Copy UK Keywords')
  const textRef = React.createRef()

  // The keywords should be a single, copyable string,
  // sorted by length (longest first)

  const keywords = clients.reduce((a, b) => {
    return [...a, ...b.keywords]
  }, [])

  const universalKwString = keywords
    .map(kw => kw.keyword)
    .sort((a, b) => b.length - a.length)
    .join(', ')

  const wmKwString = keywords
    .filter(kw => (kw.isUniversal || kw.isUk))
    .map(kw => kw.keyword)
    .sort((a, b) => b.length - a.length)
    .join(', ')

  return (
    <div className='grid'>
      <textarea
        ref={textRef}
        className='grid-1-5'
        disabled
        defaultValue={universalKwString}
      />

      <CopyToClipboard
        text={universalKwString}
        onCopy={() => setButtonText('Copied!')}
      >
        <button className='grid-1-5 btn-primary'>
          {buttonText}
        </button>
      </CopyToClipboard>

      <CopyToClipboard
        text={wmKwString}
        onCopy={() => setWmButtonText('Copied!')}
      >
        <button className='grid-1-5 btn-secondary'>
          {wmButtonText}
        </button>
      </CopyToClipboard>
    </div>
  )
}

export default PearlsField
