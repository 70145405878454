import React from 'react'
import moment from 'moment'
import OralQuestion from './OralQuestion'
import QmsSection from './QmsSection'
import ShortHeader from './ShortHeader'

const OralQuestionList = ({ groups, isShort, date }) => {
  const groupList = groups.map(g => {
    const questionList = g.questions.map(q => (
      <OralQuestion
        key={q.ref}
        question={q}
        isShort={isShort}
      />
    ))
    let typeString = g.type
    if (g.type === 'Portfolio Questions') typeString = `${g.type}: ${g.portfolio}`
    return (
      <div key={typeString}>
        <br />
        <strong> {typeString}</strong> &ndash; {moment(g.date).format('dddd D MMMM')}
        {questionList}
      </div>
    )
  })

  if (!isShort) {
    return (
      <QmsSection header='Oral Questions'>
        {groupList}
      </QmsSection>
    )
  } else if (isShort && groups.length) {
    return (
      <div>
        <ShortHeader text='Oral Questions' date={date} />
        {groupList}
      </div>
    )
  } else return ''
}

export default OralQuestionList
