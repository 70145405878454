import React from 'react'
import OralQuestionList from './OralQuestionList'
import WrittenQuestionList from './WrittenQuestionList'
import MotionList from './MotionList'

const LongQmsList = ({ written, oral, giqs, motions }) => (
  <section>
    <MotionList motions={motions} />
    <OralQuestionList groups={oral} />
    <WrittenQuestionList
      title='Government Initiated Questions'
      groups={giqs}
    />
    <WrittenQuestionList
      title='Written Questions'
      groups={written}
    />
  </section>
)

export default LongQmsList
