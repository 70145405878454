import React from 'react'
import PageLayout from '../components/shared/PageLayout'

const InfoPage = () => (
  <PageLayout>
    <h1>Information</h1>
    <article>
      <section>
        <h2>Questions &amp; Motions</h2>
        <p>
          The questions and motions page displays output from the previous day's motions, written
          questions and oral questions. Its formatting closely matches our Q&amp;Ms email, which saves time.
          The app will access entries from yesterday by default (or Friday, if it's a Monday). This matches
          the current business bulletin. If you want to pick up tomorrows bulletin, then change to today's date.
          Be aware that you may end up with only a partial list if its earlier in the afternoon.
          This is because most of the data is source from the search function rather than the bulletin itself.
        </p>
        <p>
          If you want to cover more data, use the date range form provided.
          This may be useful over recess periods, when the business bulletin is released weekly.
        </p>
        <h3>Checklist</h3>
        <p>
          You will have to do some additional manual work to prepare Q&Ms for the email.
          For full details on the steps below refer to the training document.
        </p>

        <ul>
          <li>
            Flag mentions
          </li>
          <li>
            Give info on written questions which reference earlier questions.
          </li>
          <li>
            Check the front page of the <a href='https://bb.parliament.scot'> business bulletin </a>
            for any announcements and add as a news writeup.
          </li>
          <li>
            Add news writeups for committee changes, oral question lodging date changes, and GIQs.
          </li>
          <li>
            Delete timetabling motions and financial resolutions
          </li>
          <li>
            Add motions for debate and alter their formatting
          </li>
          <li>
            Add a motions for approval header for committee motions and SSI motions. Remove the minister.
          </li>
          <li>
            Add short versions last. If it will take some time, then send them round separately.
          </li>
        </ul>
      </section>

      <section>
        <h2>Written Answers</h2>
        <p>
          The written answers page displays answers which were lodged on the selected day.
          This replicates what you see on the PDF.
          Be aware that the full list may not be available until 30-60mins after the PDF is uploaded.
          You should <strong>perform a quick count</strong> to ensure nothing has been missed from the PDF.
        </p>
        <p>
          The app will format everything as it should be in the email.
        </p>
      </section>
    </article>
  </PageLayout>
)

export default InfoPage
