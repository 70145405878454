import React from 'react'
import Linkify from 'react-linkify'

const LinkedText = ({ text }) => {
  const parliamentRootUrl = 'https://www.parliament.scot/chamber-and-committees/'
  const getMotionUrl = (ref) => `${parliamentRootUrl}votes-and-motions/votes-and-motions-search/${ref}`
  const getQuestionUrl = (ref) => `${parliamentRootUrl}questions-and-answers/question?ref=${ref}`
  const refIsMotion = (ref) => ref.includes('M')

  const getRefUrl = (ref) => {
    if (refIsMotion(ref)) {
      const refWithAmendmentDash = ref.replace('.', '-')
      return getMotionUrl(refWithAmendmentDash);
    }
    return getQuestionUrl(ref);
  }

  let parts = null
  if (typeof text === 'string') {
    const refRe = /(S\d\D-(?:\d|\.)*)/gi
    parts = text.split(refRe)
    for (let i = 1; i < parts.length; i += 2) {
      parts[i] = <a key={'a' + i} href={getRefUrl(parts[i])}>{parts[i]}</a>
    }
  }

  return (
    <Linkify>
      {(parts || text)}
    </Linkify>
  )
}

export default LinkedText
