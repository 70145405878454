import React, { Fragment, useState, useEffect } from 'react'
import LastUpdated from '../shared/LastUpdated'

const KeywordForm = ({
  isCreateForm,
  initialData,
  clients,
  createClient,
  updateClient,
  deleteClient
}) => {
  const [nameIsValid, setNameValidity] = useState(true)
  const [kwsAreValid, setKwValidity] = useState(true)

  if (isCreateForm || !initialData) {
    initialData = {
      id: '',
      name: '',
      notes: '',
      keywords: [{
        keyword: '',
        notes: '',
        isScot: true,
        isUk: true,
        isUniversal: true,
        isValid: true,
        isDeleted: false
      }]
    }
  }
  const [client, setClient] = useState(initialData)

  const validateAndCleanClient = (stripId) => {
    let data = { ...client }
    if (!data.name || data.name === '') return false
    data.keywords = []
    client.keywords.forEach(k => {
      // Trim empty ones
      if (k.keyword) k.keyword = k.keyword.trim()
      // Remove internal fields used by this form but not stored on db
      if (k.isDeleted !== undefined) delete k.isDeleted
      delete k.isValid

      data.keywords.push(k)
    })
    if (stripId) {
      data = {
        name: data.name,
        notes: data.notes,
        keywords: data.keywords
      }
    }
    return data
  }

  const handleCreate = () => {
    const data = validateAndCleanClient(true)
    if (data) createClient(data)
  }

  const handleUpdate = () => {
    const data = validateAndCleanClient(false)
    if (data) updateClient(data)
  }

  const handleDelete = () => {
    deleteClient(client.id)
  }

  // Check for commas and duplicates
  const checkKwValidity = (kw, i) => {
    if (kw.keyword.includes(',')) return false
    if (kw.keyword !== '' &&
      client.keywords.filter(k => k.keyword === kw.keyword).length > 1) {
      return false
    }
  }

  useEffect(() => {
    if (clients.find(c =>
      c.name.toLowerCase().trim() === client.name.toLowerCase().trim() &&
      c.id !== client.id
    )) {
      setNameValidity(false)
    } else setNameValidity(true)

    let kwValidity = true
    client.keywords.forEach(kw => {
      if (kw.isValid === false) kwValidity = false
      else return true
    })
    setKwValidity(kwValidity)
  }, [client, clients])

  const addKeyword = () => {
    setClient({
      ...client,
      keywords: [
        ...client.keywords, {
          keyword: '',
          notes: '',
          isScot: true,
          isUk: true,
          isUniversal: true
        }]
    })
  }
  const handleSubmit = (e) => { e.preventDefault() }

  const keywordInputs = client.keywords.map((k, i) => {
    const changeHandler = (e, i, field) => {
      const val = e.target.value
      const newKeywords = [...client.keywords]
      const kwToChange = newKeywords[i]
      switch (field) {
        case 'keyword':
          kwToChange.keyword = val
          kwToChange.isValid = checkKwValidity(kwToChange)
          break
        case 'notes':
          kwToChange.notes = val
          break
        case 'scot':
          kwToChange.isScot = e.target.checked
          break
        case 'uk':
          kwToChange.isUk = e.target.checked
          break
        case 'universal':
          kwToChange.isUniversal = e.target.checked
          break
        case 'delete':
          kwToChange.keyword = ''
          kwToChange.notes = ''
          kwToChange.isDeleted = true
          break
        default:
          console.log('Unhandled keyword form input')
          break
      }
      setClient({ ...client, keywords: newKeywords })
    }

    let kwError = ''
    if (k.isValid === false) {
      kwError = (
        <p className='error'>
          Commas and duplicates not allowed
        </p>
      )
    }

    if (k.isDeleted) return ''

    return (
      <Fragment key={`kw-${i}`}>
        <div className='grid-1-2'>
          <input
            className={(!(k.isValid === false) ? '' : 'error')}
            type='text'
            value={k.keyword}
            placeholder='Keyword'
            onChange={(e) => changeHandler(e, i, 'keyword')}
          />
          {kwError}
        </div>
        <div className='grid-2-3'>
          <input
            type='text'
            value={k.notes}
            placeholder='Notes'
            onChange={(e) => changeHandler(e, i, 'notes')}
          />
        </div>
        <div className='grid-3-4'>
          <div className='flex'>
            <div className='center'>
              <label
                htmlFor={`${client.id}-kw-${i}-scot`}
                className='small-text'
              >
                SP/SG
              </label>
              <input
                id={`${client.id}-kw-${i}-scot`}
                name='scot'
                type='checkbox'
                checked={k.isScot || k.isUniversal ? 'checked' : ''}
                disabled={k.isUniversal ? 'disabled' : ''}
                onChange={(e) => changeHandler(e, i, 'scot')}
              />
            </div>
            <div className='center'>
              <label
                htmlFor={`${client.id}-kw-${i}-uk`}
                className='small-text'
              >
                UK
              </label>
              <input
                id={`${client.id}-kw-${i}-uk`}
                name='uk'
                type='checkbox'
                checked={k.isUk || k.isUniversal ? 'checked' : ''}
                disabled={k.isUniversal ? 'disabled' : ''}
                onChange={(e) => changeHandler(e, i, 'uk')}
              />
            </div>
            <div className='center'>
              <label
                htmlFor={`${client.id}-kw-${i}-all`}
                className='small-text'
              >
                All
              </label>
              <input
                id={`${client.id}-kw-${i}-universal`}
                name='universal'
                type='checkbox'
                checked={k.isUniversal ? 'checked' : ''}
                onChange={(e) => changeHandler(e, i, 'universal')}
              />
            </div>
          </div>
        </div>
        <div className='grid-4-5'>
          <button
            className='btn-delete small'
            onClick={(e) => {
              e.preventDefault()
              changeHandler(e, i, 'delete')
            }}
          >
            <span className='material-icons'>
              clear
            </span>
          </button>
        </div>
      </Fragment>
    )
  })

  let buttons = (
    <>
      <input
        className='grid-1-3 btn-primary'
        type='submit'
        disabled={(!nameIsValid || !kwsAreValid)}
        onClick={handleUpdate}
        name='update'
        value='Update'
      />
      <input
        className='grid-3-5 btn-delete'
        type='button'
        onClick={handleDelete}
        name='delete'
        value='Delete'
      />
    </>
  )
  if (isCreateForm) {
    buttons = (
      <input
        type='submit'
        onClick={handleCreate}
        className='btn-primary grid-2-4'
        disabled={(!nameIsValid || !kwsAreValid)}
        name='create'
        value='Create'
      />
    )
  }

  let nameError = ''
  if (!nameIsValid) {
    nameError = (
      <p className='error'>
        Name already exists. It must be unique.
      </p>
    )
  }
  return (
    <form
      className={(isCreateForm ? 'grid' : 'grid grid-selected')}
      onSubmit={(e) => handleSubmit(e)}
    >
      <div className='grid-1-3'>
        <label htmlFor={`${client.id}-name`}>
          Client Name
        </label>
        <input
          className={(nameIsValid ? '' : 'error')}
          id={`${client.id}-name`}
          type='text'
          required
          name='name'
          placeholder={'Add the client\'s name here...'}
          value={client.name}
          onChange={(e) => setClient({ ...client, name: e.target.value })}
        />
        {nameError}
      </div>

      {(
        !isCreateForm ? (
          <p className='grid-4-5 last-updated'>
            <LastUpdated date={client.lastUpdated} />
          </p>
        ) : ''
      )}

      <div className='grid-1-5'>
        <label htmlFor={`${client.id}-notes`}>
          Client Notes
        </label>
        <input
          id={`${client.id}-notes`}
          type='text'
          name='notes'
          placeholder='Add any additional notes about the client or their mentions here...'
          value={client.notes}
          onChange={(e) => setClient({ ...client, notes: e.target.value })}
        />
      </div>

      {buttons}

      <button
        className='grid-2-4'
        type='button'
        onClick={() => addKeyword()}
      >
        Add Keyword
      </button>
      <div className='grid-1-2'>
        <label> Keywords </label>
      </div>
      <div className='grid-2-3'>
        <label> Notes </label>
      </div>
      <div className='grid-3-4'>
        <label> Scope </label>
      </div>
      {keywordInputs}
    </form>
  )
}

export default KeywordForm
