import React, { useState } from 'react'

const CreateTag = ({ createTag }) => {
  const [text, setText] = useState('')
  const [isError, setIsError] = useState(false)
  const [createdText, setCreatedText] = useState('')

  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      await createTag(text)
      setIsError(false)
      setCreatedText('Added!')
    } catch (error) {
      setIsError(true)
      setCreatedText('Error adding tag, does it already exist?')
    }
  }

  return (
    <form
      className='grid grid-selected'
      onSubmit={onSubmit}
    >
      <input
        className='grid-1-4'
        required
        type='text'
        name='name'
        placeholder='Name'
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <input
        className='btn-primary'
        type='submit'
        value='Add Tag'
      />
      {!!createdText.length &&
        <p className={isError ? 'error' : ''}>
          {createdText}
        </p>
      }
    </form>
  )
}

export default CreateTag
