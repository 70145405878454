import React from 'react'

const QmsSection = ({ header, children }) => {
  if (children && children.length > 0) {
    return (
      <div>
        <br />
        <h3>{header}</h3>
        <br />
        {children}
      </div>
    )
  } else {
    return (
      <div>
        <br />
        <em> No {header}. </em>
        <br />
      </div>
    )
  }
}

export default QmsSection
