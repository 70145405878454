import React, { useState } from 'react'

const MspTable = ({ msps, updateMsp, isInteractable }) => {
  const [selectedMsp, setMsp] = useState({
    id: '',
    name: '',
    party: '',
    location: '',
    title: ''
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    updateMsp(selectedMsp)
  }

  const rows = msps.map(msp => {
    const isSelected = !!(msp.id === selectedMsp.id)
    if (isSelected) {
      return (
        <form
          key={selectedMsp.id}
          className='grid grid-selected'
          onSubmit={handleSubmit}
        >
          <input
            required
            type='text'
            name='name'
            placeholder='Name'
            value={selectedMsp.name}
            onChange={(e) => setMsp({ ...selectedMsp, name: e.target.value })}
          />
          <input
            required
            type='text'
            name='party'
            placeholder='Party'
            value={selectedMsp.party}
            onChange={(e) => setMsp({ ...selectedMsp, party: e.target.value })}
          />
          <input
            required
            type='text'
            name='location'
            placeholder='Location'
            value={selectedMsp.location}
            onChange={(e) => setMsp({ ...selectedMsp, location: e.target.value })}
          />
          <input
            type='text'
            name='title'
            placeholder='Title'
            value={selectedMsp.title}
            onChange={(e) => setMsp({ ...selectedMsp, title: e.target.value })}
          />
          <input
            className='btn-primary grid-2-4'
            type='submit'
            value='Update'
          />
        </form>
      )
    } else {
      let clickHandler = () => setMsp(msp)
      if (!isInteractable) clickHandler = null
      return (
        <div
          key={msp.id}
          className='grid grid-hover'
          onClick={clickHandler}
        >
          <span> {msp.name} </span>
          <span> {msp.party} </span>
          <span> {msp.location} </span>
          <span> {msp.title} </span>
        </div>
      )
    }
  })

  return (<>{rows}</>)
}

export default MspTable
