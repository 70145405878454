import React from 'react'
import InquiryForm from './InquiryForm'
import InquiryEntry from './InquiryEntry'

const InquiryRows = ({
  inquiries,
  orgs,
  tags,
  selectedIndex,
  select,
  create,
  update,
  del,
  addTagToInquiry,
  deleteTagFromInquiry,
}) => {
  if (!inquiries || !inquiries.length) {
    return <div className="empty-container note">None</div>
  }

  const rows = inquiries.map((inq, i) => {
    if (i === selectedIndex) {
      return (
        <InquiryForm
          key={inq.id}
          isCreateForm={false}
          initialData={inq}
          inquiries={inquiries}
          update={update}
          del={del}
          allOrgs={orgs}
          tags={tags}
          addTagToInquiry={addTagToInquiry}
          deleteTagFromInquiry={deleteTagFromInquiry}
        />
      )
    } else {
      return (
        <InquiryEntry
          key={inq.id}
          inquiry={inq}
          select={() => select(i)}
          isInteractable
        />
      )
    }
  })

  return <>{rows}</>
}

export default InquiryRows
