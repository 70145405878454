import React from 'react'
import LinkedText from '../shared/LinkedText'
import LinkedTableText from './LinkedTableText'
import FullTable from './FullTable'
import MentionsLine from '../shared/MentionsLine'

const WrittenAnswer = ({ entry, showTables }) => {
  // Question

  let questionBody = (
    <>
      <LinkedText text={entry.body} /> <LinkedText text={entry.ref} />
    </>
  )
  if (entry.subQs) {
    const replaceRe = /((To ask the Scottish Government)|(To ask the Scottish Parliamentary Corporate Body)),?/gi
    const subQList = entry.subQs.map(subQ => (
      <li key={subQ.ref}>
        <LinkedText text={subQ.body.replace(replaceRe, '')} /> <LinkedText text={subQ.ref} />
      </li>
    ))
    const leadText = entry.isSPCB ? 'To ask the SPCB;' : 'To ask the Scottish Government:'
    questionBody = (
      <>
        {leadText}
        <ul className='entry-list'>
          {subQList}
        </ul>
      </>
    )
  }

  // Answer

  if (!entry.answerEntries) return ''

  const textEntry = (e, i) => {
    if (i === 0) {
      return (<span key={i}> <strong>{entry.answerMsp.title}, {entry.answerMsp.name}: </strong> <LinkedText text={e.data} /> </span>)
    } else {
      return (<span key={i}> <LinkedText text={e.data} /> </span>)
    }
  }

  const listEntry = (e, i) => {
    const bullets = e.data.map((b, j) => (
      <li key={j}>
        <LinkedText text={b} />
      </li>
    ))
    return (<ul key={i}> { bullets } </ul>)
  }

  const tableEntry = (e, i) => {
    if (!showTables) return (<LinkedTableText key={i} spRef={entry.ref} />)
    else return (<FullTable key={i} data={e.data} />)
  }

  const answerBody = entry.answerEntries.map((e, i) => {
    if (e.type === 'text') return textEntry(e, i)
    else if (e.type === 'list') return listEntry(e, i)
    else if (e.type === 'table') return tableEntry(e, i)
    return ''
  })

  return (
    <div>
      <br />
      <div>
        <p><MentionsLine mentions={entry.mentions} /></p>
        <strong> {entry.msp.name} ({entry.msp.location}) ({entry.msp.party}): </strong>
        {questionBody}
      </div>
      <div>
        {answerBody}
      </div>

    </div>
  )
}

export default WrittenAnswer
