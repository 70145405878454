import React, { useState } from 'react'

const InquiryFilter = ({ updateFilter }) => {
  const [currentFilter, setCurrentFilter] = useState('')

  return (
    <div className='grid outline-grid'>
      <div className='grid-1-5'>
        <label htmlFor='inquiry-filter'>
              Filter (by Title or Institution)
        </label>
        <input
          id='inquiry-filter'
          type='text'
          placeholder='Filter..'
          value={currentFilter}
          onChange={(e) => setCurrentFilter(e.target.value)}
        />
      </div>
      <input
        className='grid-1-3 btn-secondary'
        type='submit'
        onClick={() => updateFilter(false, currentFilter)}
        name='search'
        value='Search Names'
      />
      <input
        className='grid-3-5 btn-secondary'
        type='submit'
        onClick={() => updateFilter(true, currentFilter)}
        name='search'
        value='Search Orgs'
      />
    </div>
  )
}

export default InquiryFilter
