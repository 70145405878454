import React, { useState } from 'react'
import moment from 'moment'

const DateForm = ({ initialStartDate, initialEndDate, submitDates }) => {
  const [state, setState] = useState({
    startDate: initialStartDate.format('YYYY-MM-DD'),
    endDate: initialEndDate.format('YYYY-MM-DD'),
    dateRange: !initialStartDate.isSame(initialEndDate)
  })

  // Checks validity of dates and ensures end date is after start date before submitting
  const handleSubmit = (e) => {
    e.preventDefault()
    const newStartDate = moment(state.startDate)
    let newEndDate = newStartDate
    if (state.dateRange) {
      newEndDate = moment(state.endDate)
    }
    if (newEndDate.isBefore(newStartDate)) {
      newEndDate = newStartDate
    }
    if (newStartDate.isValid() && newEndDate.isValid()) {
      submitDates({ startDate: newStartDate, endDate: newEndDate })
    }
  }

  const handleChange = (e, field) => {
    const val = e.target.value
    if (state.dateRange) {
      if ((field === 'startDate' && val <= state.endDate) ||
        (field === 'endDate' && val >= state.startDate)) {
        const newState = { ...state }
        newState[field] = val
        setState(newState)
      }
    } else setState({ ...state, startDate: val, endDate: val })
  }

  const greyIfNotRange = (state.dateRange ? '' : 'greyed')
  return (
    <form onSubmit={handleSubmit}>
      <div className='flex'>
        <div>
          <label htmlFor='start-date'>
            {(state.dateRange ? 'Start Date' : 'Date')}
          </label>
          <input
            type='date'
            id='start-date'
            name='start-date'
            value={state.startDate}
            onChange={(e) => handleChange(e, 'startDate')}
          />
        </div>
        <div>
          <label className={greyIfNotRange} htmlFor='end-date'>
            End Date
          </label>
          <input
            className={greyIfNotRange}
            disabled={(!state.dateRange)}
            type='date'
            id='end-date'
            name='end-date'
            value={state.endDate}
            onChange={(e) => handleChange(e, 'endDate')}
          />
        </div>
      </div>
      <button
        type='button'
        onClick={() => setState({ ...state, dateRange: !(state.dateRange) })}
      >
        {state.dateRange ? 'Use Single Day' : 'Use Date Range'}
      </button>
      <input className='btn-primary' type='submit' value='Submit' />
    </form>
  )
}

export default DateForm
