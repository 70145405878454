import React from 'react'

const FullTable = ({ data }) => {
  const headers = data[0].map((r, i) => <th key={i}>{r.trim()}</th>)
  const rowData = [...data].splice(1, data.length)
  const rows = rowData.map((r, rI) => {
    const entries = r.map((e, i) => <td key={i}>{e.trim()}</td>)
    return (<tr key={rI}>{entries}</tr>)
  })

  return (
    <table>
      <thead>
        <tr>
          {headers}
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  )
}

export default FullTable
