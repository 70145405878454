import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useEntryApi } from '../lib/api'
import PageLayout from '../components/shared/PageLayout'
import DateForm from '../components/shared/DateForm'
import DateInfo from '../components/shared/DateInfo'
import Loader from '../components/shared/Loader'
import WmWrittenAnswerList from '../components/wm/WmWrittenAnswerList'
import ToggleScotInfoButton from '../components/wm/ToggleScotInfoButton'
import '../styles/report.css'

const WrittenAnswersPage = ({ loginData }) => {
  const [{ entryState }, getEntries] = useEntryApi({
    token: loginData.token,
    endpoint: 'wm-entries'
  })

  const [showScot, setShowScot] = useState(true)
  const toggleScot = () => { setShowScot(!showScot) }

  useEffect(() => {
    const defaultDate = moment().subtract(1, 'days')
    getEntries({ startDate: defaultDate, endDate: defaultDate })
    // eslint-disable-next-line
  }, [])

  if (!entryState.isFetching) {
    return (
      <PageLayout>
        <h1>Westminster Written Answers</h1>

        <DateForm
          initialStartDate={entryState.startDate}
          initialEndDate={entryState.endDate}
          submitDates={getEntries}
        />
        <DateInfo
          startDate={entryState.startDate}
          endDate={entryState.endDate}
          showBBLine={false}
        />

        <article id='report-container'>

          {(
            entryState.wmWrittenAnswers &&
            entryState.wmWrittenAnswers.length
              ? <ToggleScotInfoButton showScot={showScot} toggleScot={toggleScot} />
              : ''
          )}

          <WmWrittenAnswerList answers={entryState.wmWrittenAnswers} showScot={showScot} />
        </article>
      </PageLayout>
    )
  } else {
    return (
      <PageLayout>
        <Loader />
      </PageLayout>
    )
  }
}

export default WrittenAnswersPage
