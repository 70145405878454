import React from 'react'
import { getDefaultDate } from '../../lib/utils'
import moment from 'moment'

const DateInfo = ({ startDate, endDate, showBBLine }) => {
  const defDate = getDefaultDate(moment()).format('YYYY-DD-MM')
  const onDefaultDate = (
    defDate === startDate.format('YYYY-DD-MM') &&
    defDate === endDate.format('YYYY-DD-MM')
  )

  let infoLine = `From ${startDate.format('dddd D MMMM')} to ${endDate.format('dddd D MMMM')}.`

  if (onDefaultDate && showBBLine) {
    infoLine = 'Showing entries from today\'s business bulletin.'
  } else if (startDate.isSame(endDate)) {
    infoLine = `${startDate.format('dddd D MMMM')}`
  }

  return (
    <p>
      <em>
        {infoLine}
      </em>
    </p>
  )
}

export default DateInfo
