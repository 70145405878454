import React from 'react'
import 'core-js'
import MentionsLine from '../shared/MentionsLine'
import LinkedText from '../shared/LinkedText'

const WrittenQuestionEntry = ({ group }) => {
  if (group.questions.length === 1) {
    const q = group.questions[0]
    const { ref, body } = q
    const hasMentions = q.mentions && q.mentions.length
    return (
      <div>
        <br />
        {(hasMentions ? <><p><MentionsLine mentions={q.mentions} /></p></> : '')}
        <p>
          <strong> {group.msp.name} ({group.msp.location}) ({group.msp.party}): </strong>
          <LinkedText text={body} /> {' '}
          <LinkedText text={ref} />
        </p>
      </div>
    )
  } else {
    const allMentions = [...new Set(
      group.questions.map(q => q.mentions).flat()
    )]
    const leadText = group.questions[0].isSPCB ? 'To ask the SPCB;' : 'To ask the Scottish Government:'
    const replaceRe = /((To.ask.the.Government)|(To.ask.the.Scottish.Government)|(To.ask.Scottish.Government)|(To.ask.the.Scottish.Parliamentary.Corporate.Body)),?/gi
    const questionList = group.questions.map(q => {
      return (
        <li key={q.ref}>
          <LinkedText text={q.body.replace(replaceRe, '').trim()} /> {' '}
          <LinkedText text={q.ref} />
        </li>
      )
    })
    return (
      <div>
        <br />
        <p><MentionsLine mentions={allMentions} /></p>
        <p>
          <strong>{group.msp.name} ({group.msp.location}) ({group.msp.party}): </strong>
          {leadText}
        </p>
        <ul className='entry-list'>
          {questionList}
        </ul>
      </div>
    )
  }
}

export default WrittenQuestionEntry
