import React from 'react'
import WmWrittenAnswer from './WmWrittenAnswer'

const WmDepartmentGroup = ({ department, answers, showScot }) => {
  const answerList = answers.map((a, i) => <WmWrittenAnswer key={i} answer={a} showScot={showScot} />)

  return (
    <>
      <div>
        <h3>{department}</h3>
        {answerList}
      </div>
      <br/>
    </>
  )
}

export default WmDepartmentGroup
