import React, { useEffect } from 'react'
import PageLayout from '../components/shared/PageLayout'
import Loader from '../components/shared/Loader'
import TagItem from '../components/tagging/TagItem'
import CreateTag from '../components/tagging/CreateTag'
import { useTagApi } from '../lib/api'

const TagsPage = ({ loginData }) => {
  const [{ tagState }, getTags, createTag, updateTag, deleteTag] = useTagApi({ token: loginData.token })

  useEffect(() => {
    getTags()
    // eslint-disable-next-line
  }, [])

  if (!tagState.isFetching) {
    return (
      <PageLayout>
        <h1>Tags</h1>
        <article>
          <CreateTag createTag={createTag} />
          {tagState.tags.reverse().map(tag => !!tag &&
          <TagItem
            key={tag.id}
            tag={tag}
            updateTag={updateTag}
            deleteTag={deleteTag}
          />
          )}
        </article>
      </PageLayout>
    )
  } else {
    return (
      <PageLayout>
        <Loader />
      </PageLayout>
    )
  }
}

export default TagsPage
