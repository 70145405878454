import React from 'react'
import OralQuestionList from './OralQuestionList'
import ShortHeader from './ShortHeader'

const ShortQmsList = ({ oral, written, motions, date }) => {
  const writtenList = written.map(wq => (
    <li key={wq.mspName}>
      <strong>{wq.mspName} </strong>&ndash;&nbsp;{(wq.isGiq ? '(GIQ)' : '')}
    </li>
  ))

  const motionList = motions.map(m => {
    const titles = m.motions.map(motion => {
      let title = motion.title
      if (motion.isMb) title += ' (potential MB)'
      return title
    }).join(', ')

    return (
      <li key={m.mspName}>
        <strong>{m.mspName} </strong>&ndash; {titles}
      </li>
    )
  })

  if (oral.length || written.length || motions.length) {
    return (
      <section>
        <h2> Short Versions </h2>
        <div>
          <br />
          <ShortHeader text='Motions' date={date} />
          <ul className='entry-list'>
            {motionList}
          </ul>
        </div>
        <br />
        <OralQuestionList groups={oral} isShort date={date} />
        <br />
        <div>
          <ShortHeader text='Written Questions' date={date} />
          <ul className='entry-list'>
            {writtenList}
          </ul>
        </div>
      </section>
    )
  } else return ''
}

export default ShortQmsList
