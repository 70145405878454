import React, { useState, useEffect } from "react";
import PageLayout from "../components/shared/PageLayout";
import Loader from "../components/shared/Loader";
import Downloads from "../components/inquiries/Downloads";
import NdConfirmAlert from "../components/shared/NdConfirmAlert";
import InquiryForm from "../components/inquiries/InquiryForm";
import InquiryFilter from "../components/inquiries/InquiryFilter";
import InquiryRows from "../components/inquiries/InquiryRows";
import { useInquiryApi, useTagApi } from "../lib/api";

const InquiryPage = ({ loginData }) => {
  const [
    { inquiryState },
    getInquiries,
    createInquiry,
    updateInquiry,
    deleteInquiry,
    getOrganisations,
    orgs
  ] = useInquiryApi({ token: loginData.token });

  const [
    { tagState },
    getTags,
    // eslint-disable-next-line
    createTag,
    // eslint-disable-next-line
    updateTag,
    // eslint-disable-next-line
    deleteTag,
    addTagToInquiry,
    deleteTagFromInquiry,
  ] = useTagApi({ token: loginData.token });

  const [selectedIndex, select] = useState(-1);

  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    getOrganisations();
    getTags();
    // eslint-disable-next-line
  }, []);

  const onUpdateFilter = (searchOrgs, filter) => {
    if (searchOrgs) {
      getInquiries({ "source-org-name": filter });
    } else {
      getInquiries({ name: filter });
    }
  };

  const create = async (inquiry) => {
    setIsUpdating(true)
    try {
      await createInquiry(inquiry);
      NdConfirmAlert({
        title: "Created",
        message: "Inquiry has been created",
        confirmFunc: () => {},
        hideNoButton: true
      });
    } catch (err) {
    NdConfirmAlert({
      title: "Error",
      message: "Error creating inquiry",
      messageHTML: err,
      confirmFunc: () => {},
      hideNoButton: true
    });
    } finally {
      setIsUpdating(false)
      select(-1);
    }
  };

  const update = async (inquiry) => {
    setIsUpdating(true)
    try {
      await updateInquiry(inquiry);
      NdConfirmAlert({
        title: "Updated",
        message: "Inquiry has been updated",
        confirmFunc: () => {},
        hideNoButton: true
      });
    } catch (err) {
      NdConfirmAlert({
        title: "Error",
        message: "Error updating inquiry",
        messageHTML: err,
        confirmFunc: () => {},
        hideNoButton: true
      });
    } finally {
      setIsUpdating(false)
      select(-1);
    }
  };

  const del = async (id) => {
    NdConfirmAlert({
      title: "Confirm",
      message: "Delete this inquiry?",
      confirmFunc: () => {
        deleteInquiry(id);
        select(-1);
      },
    });
  };

  if (!tagState.isFetching && !isUpdating) {
    return (
      <PageLayout>
        <h1>Inquiries</h1>

        <Downloads />

        {orgs && orgs.length && (
          <>
            <h2>Add New Inquiry</h2>
            <InquiryForm
              isCreateForm
              inquiries={inquiryState.inquiries}
              create={create}
              update={update}
              del={del}
              allOrgs={orgs}
            />
          </>
        )}

        <hr />

        <h2> Existing Inquiries </h2>
        <InquiryFilter updateFilter={onUpdateFilter} />

        {inquiryState.inquiries && inquiryState.inquiries.length && (
          <InquiryRows
            inquiries={inquiryState.inquiries}
            orgs={orgs}
            tags={tagState.tags}
            selectedIndex={selectedIndex}
            select={select}
            create={create}
            update={update}
            del={del}
            addTagToInquiry={addTagToInquiry}
            deleteTagFromInquiry={deleteTagFromInquiry}
          />
        )}
      </PageLayout>
    );
  } else {
    return (
      <PageLayout>
        <Loader />
      </PageLayout>
    );
  }
};

export default InquiryPage;
