import React, { useEffect } from 'react'
import { useEntryApi, useClientApi } from '../lib/api'
import { getDefaultDate } from '../lib/utils'
import moment from 'moment'
import DateForm from '../components/shared/DateForm'
import DateInfo from '../components/shared/DateInfo'
import LongQmsList from '../components/qms/LongQmsList'
import ShortQmsList from '../components/qms/ShortQmsList'
import PageLayout from '../components/shared/PageLayout'
import Loader from '../components/shared/Loader'
import '../styles/report.css'

const QuestionsMotionsPage = ({ loginData }) => {
  const [{ entryState }, getEntries] = useEntryApi({
    token: loginData.token,
    endpoint: 'questions-and-motions'
  })
  const [{ clientState }, getClients] = useClientApi({ token: loginData.token })

  useEffect(() => {
    const defaultDate = getDefaultDate(moment())
    getClients()
    getEntries({ startDate: defaultDate, endDate: defaultDate })
    // eslint-disable-next-line
  }, [])

  if (!entryState.isFetching && !clientState.isFetching) {
    return (
      <PageLayout>
        <h1>Questions &amp; Motions</h1>
        <DateForm
          initialStartDate={entryState.startDate}
          initialEndDate={entryState.endDate}
          submitDates={getEntries}
        />
        <DateInfo
          startDate={entryState.startDate}
          endDate={entryState.endDate}
          showBBLine
        />
        <hr />
        <article id='report-container'>
          <LongQmsList
            written={entryState.writtenQuestions}
            oral={entryState.oral}
            giqs={entryState.giqs}
            motions={entryState.motions}
          />
          <hr />
          <br />
          <ShortQmsList
            oral={entryState.oral}
            date={entryState.startDate}
            written={entryState.shortWrittenQuestions}
            motions={entryState.shortMotions}
          />
        </article>
      </PageLayout>
    )
  } else {
    return (
      <PageLayout>
        <Loader />
      </PageLayout>
    )
  }
}

export default QuestionsMotionsPage
