import React, { useState } from 'react'
import Select from 'react-select'

const InquiryTagger = ({ inquiry, tags, addTagToInquiry, deleteTagFromInquiry }) => {

  let optionsFromTags = tags ? tags.map(tag => { return { value: `${tag.id}`, label: tag.text }}) : []
  let valuesFromTags = inquiry.tags && inquiry.tags.map(tag => { return { value: `${tag.id}`, label: tag.text }});

  const [value, setValue] = useState(valuesFromTags);

  const handleSelect = async (option) => {
    try {
      await addTagToInquiry({ inquiryId: inquiry.id, tagId: option.value })
      inquiry.tags.push({ id: option.value, text: option.label })
    } catch (err) {
      console.error(err)
    }
  }

  const handleUnselect = async (removedValue) => {
    try {
      await deleteTagFromInquiry({ inquiryId: inquiry.id, tagId: removedValue.value })
      inquiry.tags.splice(inquiry.tags.findIndex(tag => tag.id === removedValue.value), 1);
    } catch (err) {
      console.error(err)
    }
  }

  const handleClear = async (removedValues) => {
    for (const removedValue of removedValues) {
      await handleUnselect(removedValue)
    }
  }

  const onChange = (value, action) => {
    setValue(value);

    const SELECT_ACTION = 'select-option'
    const UNSELECT_ACTION = 'remove-value'
    const CLEAR_ACTION = 'clear'

    switch(action.action) {
      case SELECT_ACTION:
        handleSelect(action.option)
        break;
      case UNSELECT_ACTION:
        handleUnselect(action.removedValue)
        break;
      case CLEAR_ACTION:
        handleClear(action.removedValues)
      break;
      default: return
    }
  }

  return (
    <div className='grid-1-5 form-addon'>
      <h3> Tags </h3>
      {optionsFromTags && (
        <Select
          isMulti
          options={optionsFromTags}
          onChange={onChange}
          value={value}
        />
      )}
    </div>
  );
}

export default InquiryTagger
