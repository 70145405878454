import React from 'react'

const InquiryEntry = ({ inquiry, select, isInteractable }) => {
  let className = 'grid grid-hover'
  if (!isInteractable) {
    className = 'grid'
    select = null
  }
  if (!inquiry.tags || !inquiry.tags.length) {
    className += ' grid-red'
  }
  return (
    <div
      className={className}
      onClick={select}
    >
      <h3 className='grid-1-4'>{inquiry.name}</h3>
      <p className='grid-4-5 last-updated'>
        Added: {inquiry.added}
      </p>
      <p className='grid-1-5 note'>
        <strong>
          {inquiry.sourceOrgName}
        </strong>
      </p>
      <p className='grid-1-5 note'>
        {
          inquiry.tags && inquiry.tags.length ?
            inquiry.tags.map(t => t.text).join(', ') :
            (<strong>No tags</strong>)
        }
      </p>
    </div>
  )
}

export default InquiryEntry
