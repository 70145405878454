import React from 'react'
import WrittenAnswer from './WrittenAnswer'

const WrittenAnswerList = ({ answers, showTables }) => {
  const answerElems = answers.map((a) => (
    <WrittenAnswer key={a.ref} entry={a} showTables={showTables} />
  ))

  if (answerElems.length) {
    return (
      <section>
        <h2> Full Versions </h2>
        {answerElems}
      </section>
    )
  } else {
    return (
      <section>
        <p>
          <em> No data currently.</em>
        </p>
      </section>
    )
  }
}

export default WrittenAnswerList
