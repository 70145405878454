import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import '../../styles/confirm-alert.css'
import 'react-confirm-alert/src/react-confirm-alert.css'

const NdConfirmAlert = ({ title, message, messageHTML, hideNoButton, confirmFunc }) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='confirm-alert'>
          <h2>{title}</h2>
          <p>{message}</p>
          {messageHTML}
          <button
            onClick={() => {
              confirmFunc()
              onClose()
            }}
          >
            OK
          </button>
          {!hideNoButton && <button onClick={onClose}>No</button>}
        </div>
      )
    }
  })
}

export default NdConfirmAlert
