import React from 'react'

const LinkedTableText = ({ spRef }) => {
  const refUrl = `https://www.parliament.scot/parliamentarybusiness/28877.aspx?SearchType=Advance&ReferenceNumbers=${spRef}`

  return (
    <p>
      Click <a href={refUrl}>here</a> to view table.
    </p>
  )
}

export default LinkedTableText
