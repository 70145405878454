import React from 'react'

const SourceOrgSelect = ({ id, initialValue, onChangeHandler, orgs, useText }) => {
  if (!orgs || orgs.length === 0) return <p className='error'> Error getting organisations</p>
  if (useText) {
    return (
      <input
        id={id}
        onChange={onChangeHandler}
        type="text"
        value={initialValue}
      >
      </input>
    )
  } else {
    const options = orgs.map((o, i) => <option key={i} value={o.name}>{o.name}</option>)
    return (
      <select
        id={id}
        value={initialValue}
        onChange={onChangeHandler}
      >
        {options}
      </select>
    )
  }
}

export default SourceOrgSelect
