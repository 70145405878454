import React from 'react'
import WrittenQuestionEntry from './WrittenQuestionEntry'
import QmsSection from './QmsSection'

// Written Questions must be grouped by the asking MSP
// Regular WQs are further grouped into those directed at the SG, and the SPCB
// Government Initiated questions must appear under their own header, which is done at the upper level
const WrittenQuestionList = ({ title, groups }) => {
  const questionList = groups.map((g, i) => {
    return (<WrittenQuestionEntry key={i} group={g} />)
  })
  return (
    <QmsSection header={title}>
      {questionList}
    </QmsSection>
  )
}

export default WrittenQuestionList
