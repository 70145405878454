import React from 'react'
import MentionsLine from '../shared/MentionsLine'
import LinkedText from '../shared/LinkedText'

const MotionEntry = ({ motion, isAmendment }) => {
  const pmbText = motion.isMb ? ' (potential Members\' Business) ' : ''
  const motionText = motion.body
  const hasMentions = motion.mentions && motion.mentions.length

  const getMotionAmendments = () => {
    if (motion.amendments) {
      return (
        <ul className='entry-list'>
          {motion.amendments.map(a => <MotionEntry isAmendment key={a.ref} motion={a} />)}
        </ul>
      )
    } else return null
  }

  const getMotionLine = () => {
    if (isAmendment || motion.amendments) {
      return (
        <><strong>{motion.msp.name}: {motion.title}</strong>&mdash;</>
      )
    } else {
      return (
        <><strong>{motion.msp.name} ({motion.msp.location}) ({motion.msp.party}): {motion.title}{pmbText}</strong>&mdash;</>
      )
    }
  }

  if (!isAmendment) {
    return (
      <div>
        <br />
        {(hasMentions ? <><p><MentionsLine mentions={motion.mentions} /></p></> : '')}
        <p>
          <LinkedText text={motion.ref} /> {getMotionLine()}
          <LinkedText text={motionText} />
        </p>
        {getMotionAmendments()}
      </div>
    )
  } else {
    return (
      <>
        <li>
          <LinkedText text={motion.ref} /> {getMotionLine()}
          <LinkedText text={motionText} />
        </li>
        {(hasMentions ? <><li><MentionsLine mentions={motion.mentions} /></li></> : '')}
      </>
    )
  }
}

export default MotionEntry
