import React from 'react'

const ShortWrittenAnswerList = ({ msps }) => {
  const mspList = msps.map(msp => <li key={msp}><strong>{msp}</strong>&ndash;</li>)

  if (mspList.length) {
    return (
      <section>
        <h2> Short Versions </h2>
        <br />
        <strong>Written Answers</strong> &ndash; click for full text
        <ul className='entry-list'>
          {mspList}
        </ul>
      </section>
    )
  } else {
    return ''
  }
}

export default ShortWrittenAnswerList
