import React from 'react'
import MentionsLine from './MentionsLine'

const CollectedMentions = ({ entries }) => {
  // Build list of unique mentions from entries
  let mentions = []
  for (const e of entries) {
    if (e.mentions) mentions = [...mentions, ...e.mentions]
  }
  const uniqueMentions = Array.from(new Set(mentions))

  if (uniqueMentions.length) {
    return (
      <>
        <hr/>
        <h3>Collected Mentions</h3>
        <br/>
        <MentionsLine mentions={uniqueMentions} />
        <br/>
        <hr/>
      </>
    )
  } else return ''
}

export default CollectedMentions
